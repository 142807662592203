::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.App {
  font-family: "Open Sans", sans-serif;
  background-color: #1d2a35;
  color: rgba(255, 255, 255, 0.87);
  margin: 0;
  padding: 4rem 0 2rem 0;
  min-height: calc(100vh - 6rem);
  width: 100vw;
  position: absolute;
  top: 0;
  height: auto;
  overflow-x: hidden;
}

p {
  line-height: 1.5;
}

.nav {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 200;
  width: 100vw;
  height: 4rem;
  top: 0;
  background-color: #38444d;
  padding-right: 1rem;
  right: 0rem;
}

.nav .name {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

.nav a {
  margin: 0.5rem;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.87);
}

.nav a:hover {
  font-weight: bold;
}

.nav a.active {
  font-weight: bold;
  cursor: default;
}

#walkthrough {
  max-width: 50rem;
}

.walkthrough-content {
  width: calc(100% - 5em);
  position: relative;
  left: 2.5em;
  max-height: calc(100vh - 9.5em);
  overflow: auto;
  height: 40em;
}

.walkthrough-content h2 {
  text-align: center;
}

#walkthrough-list li {
  list-style: circle;
  line-height: 1.5;
}

.walkthrough-img {
  position: relative;
  left: 1.5em;
  width: 50px;
  height: 50px;
}

.walkthrough-img:hover {
  cursor: pointer;
  content: url("../public/walkthrough-hover.png");
  transition: content 0.3s;
}

.page {
  cursor: pointer;
}

.position {
  position: relative;
}

.page-arrow {
  height: 2.5em;
  width: 2.5em;
  position: absolute;
  top: 50%;
  transform: translate(0, -25%);
}

.page-left {
  left: -1em;
}

.page-left:hover {
  cursor: pointer;
  content: url("../public/page-left-hovered.png");
  transition: content 0.3s;
}

.page-right {
  right: -1em;
}

.page-right:hover {
  cursor: pointer;
  content: url("../public/page-right-hovered.png");
  transition: content 0.3s;
}

button:hover {
  background-color: #868686;
  cursor: pointer;
}

.div-container {
  display: flex;
}

.container {
  border-radius: 1rem;
  width: 28.2%;
  height: 100%;
  margin: 1rem 1.5%;
  padding: 1%;
  background-color: #38444d;
}

.profile {
  width: 45%;
}

.container label {
  font-weight: bold;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: max-content;
}

.register h1,
.login h1 {
  text-align: center;
}

.register,
.login {
  width: 30%;
  position: absolute;
  left: 50%;
  top: calc(50% - 4rem);
  transform: translate(-50%, -50%);
  height: 20em;
  max-height: 70%;
  min-height: auto;
  display: flex;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
}

.button-container {
  display: flex;
  justify-content: right;
  margin-top: auto;
}

.link {
  background: none;
  border: none;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.87);
  font-size: 12px;
}

.link:hover {
  cursor: pointer;
  color: rgba(172, 237, 255, 0.87);
  background: none;
}

#need-help {
  padding: 0;
  margin: 0.5rem 0 0 0;
  text-align: left;
}

.auth-flex-end {
  align-items: flex-end;
}

#otp {
  width: 3em;
  height: 3em;
  margin: 1em;
  padding: auto;
  text-align: center;
}

#v-p {
  text-align: center;
}

.otp-container {
  justify-content: center;
}

.verification {
  top: calc(50% - 2rem);
  height: 25em;
}

.verification .message {
  margin: 0 0 1em 0;
  text-align: center;
  color: rgba(199, 255, 171, 0.87);
}

.verification .error-message3 {
  margin: 0 0 1em 0;
  text-align: center;
  color: rgba(240, 58, 71, 0.87);
}

.verification .button-container {
  justify-content: space-between;
  align-items: flex-end;
}

.verification .button-container p {
  margin: 0;
}

.verification .button-container button {
  height: 2em;
}

.message {
  color: rgba(199, 255, 171, 0.87);
}

.background-container {
  width: 100vw;
  max-width: 100vw;
  height: calc(100vh - 4rem);
  max-height: calc(100vh - 4rem);
  position: absolute;
  bottom: 0;
  background-image: url("../public/background_cropped.webp"),
    url("../public/background_cropped.jpg");
  background-size: cover;
  background-position: 18% center;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;
}

.home {
  width: 50%;
  height: auto;
  padding: 1rem 2rem;
  font-size: min(1vw, 2vh);
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(37, 37, 37, 0.8);
  border-radius: 1rem;
}

#quote {
  font-family: "Merriweather", serif;
  font-style: italic;
}

#socrates {
  position: relative;
}

.error-message {
  color: rgba(240, 58, 71, 0.87);
  margin: 0.5em 0;
}

.error-message2 {
  color: rgba(240, 58, 71, 0.87);
  margin: 1em 0 0 0;
}

.weight-change-container {
  text-align: center;
}

.weight-change {
  margin-top: 0.5rem;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.weight-change div p {
  margin: 0;
}

#weight-change {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

#positive {
  color: rgba(138, 201, 38, 1);
}

#negative {
  color: rgba(240, 58, 71, 1);
}

.time-selection-container {
  text-align: right;
  margin: 0.5rem 1rem 0 0;
}

.stats {
  padding-right: 0.1em;
}

.weightGraph,
.exerciseGraph {
  width: 99%;
  margin: 0 auto;
}

.exercise-graph-selector {
  padding: 0 0.25em;
}

.exercise-graph-selector:hover {
  background-color: #38444d;
  cursor: pointer;
}

#exercise-graph-selector-clicked {
  background-color: #38444d;
}

.selected-graph {
  height: 1.5em;
  width: 2em;
}

.weight-list ul {
  list-style: none;
  padding: 1rem;
  height: 7rem;
  overflow-y: auto;
  background-color: #1d2a35;
}

.weight-list ul li {
  padding: 0 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
}

.weight-list ul #dates {
  text-align: right;
}

.img {
  height: 1rem;
  width: 1rem;
  margin: 0 0.1rem;
}

.sort {
  height: 1.5rem;
  width: 1rem;
}

.delete:hover {
  cursor: pointer;
  content: url("../public/delete-hover.png");
  transition: content 0.3s;
}

.x:hover {
  cursor: pointer;
  content: url("../public/x-hover.png");
  transition: content 0.3s;
}

.edit:hover {
  cursor: pointer;
  content: url("../public/edit-hover.png");
  transition: content 0.3s;
}

.sort:hover {
  cursor: grab;
}

.form input {
  margin: 0.5rem 0;
  width: 16rem;
}

.form #input {
  margin: 1rem 0;
  width: 8rem;
}

a {
  text-decoration: none;
}

.form select {
  margin: 1px 2px;
  border: 2px;
}

.profile .spec {
  justify-content: space-between;
  width: 50%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  height: 3.5rem;
}

.profile h2 {
  border-bottom: 2px solid #1d2a35;
  padding: 1rem 0;
  margin: 0;
}

.flex {
  display: flex;
  align-items: center;
}

.flex button {
  margin: 0.5rem 0.1rem;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.dashboard {
  transform: translate3d(0, 0, 0);
  justify-content: space-between;
  position: relative;
}

.dashboard button {
  width: 4rem;
}

.dashboard h3 {
  margin: 0.8rem 0;
  padding-bottom: 0.4em;
}

.title {
  border-bottom: 2px solid #1d2a35;
  height: 5rem;
}

.list-title-card {
  border-bottom: 2px solid #1d2a35;
}

.list-title-card h3:hover {
  cursor: pointer;
}

.workout-list-clickable {
  width: 90%;
  cursor: pointer;
}

.workout-list-clickable:hover .up-arrow {
  content: url("../public/double-up-arrow.png");
}

.workout-list-clickable:hover .down-arrow {
  content: url("../public/double-down-arrow.png");
}

.tracked-clickable {
  width: 100%;
}

.tracked-clickable:hover .up-arrow {
  content: url("../public/double-up-arrow.png");
}

.tracked-clickable:hover .down-arrow {
  content: url("../public/double-down-arrow.png");
}

.shift-left {
  transform: translate(-1.2em);
}

.tooltip-png {
  width: 1.5rem;
  height: 1.5rem;
}

.tooltip-png2 {
  width: 0.9rem;
  height: 0.9rem;
  margin-right: 0.3em;
}

.tooltip-png:hover,
.tooltip-png2:hover {
  cursor: pointer;
  content: url("../public/tooltip-hover.png");
  transition: content 0.3s;
}

.tooltip-container {
  position: relative;
  z-index: 100;
}

.tooltip {
  position: absolute;
  right: 0;
  background-color: #333;
  color: #fff;
  padding: 0 0.5em;
  font-size: 0.9em;
  width: 30em;
  top: 60%;
  transform: translateX(-5.5%);
}

.tooltip-exercise {
  left: 6%;
  padding: 0.5em;
}

.tooltip-tracked {
  left: 38%;
  padding: 0.5em;
  z-index: 100;
}

#sets {
  top: -140%;
}

#reps {
  top: -230%;
}

#weight {
  top: -210%;
}

#track {
  top: -440%;
}

#bw {
  top: -550%;
}

#working-weight {
  top: -110%;
  z-index: 100;
  left: 3em;
}

#volume {
  top: -160%;
  z-index: 100;
  left: 3em;
}

.bold {
  font-weight: bold;
}

#edit {
  width: 1rem;
  height: 1rem;
  margin: 0;
  position: relative;
  top: 0.2rem;
}

.down-arrow,
.up-arrow {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0.25rem 0;
  align-self: flex-end;
  font-size: large;
  width: 1em;
  height: 1em;
}

.dropdown-menu {
  background-color: #1d2a35;
}

.tracked-dropdown {
  max-height: 10rem;
  overflow-y: auto;
  transition: max-height 0.3s ease;
}

.tracked .dropdown-menu {
  padding-bottom: 1rem;
}

.dropdown-menu button {
  height: 1.5rem;
  width: 4rem;
  margin: 0.25rem 0.05rem;
}

.plus-container {
  text-align: center;
}

#list-plus {
  display: inline-block;
  margin: 0;
}

#list-plus:hover {
  cursor: pointer;
  color: rgba(138, 201, 38, 1);
}

.exercise-modal-body {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  width: 100%;
  max-width: calc(100% - 3rem);
}

.exercise-modal-body #name {
  width: 10rem;
}

.space-between {
  justify-content: space-between;
  padding-left: 1em;
}

#wide {
  width: 4rem;
}

#wider {
  width: 14em;
}

#narrow {
  width: 2.5rem;
}

.exercise-modal-body p {
  margin: 0;
}

.exercise-modal-body div {
  margin: 0.3rem 0;
  word-wrap: break-word;
}

.modal-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.unordered-list {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.dropdown-menu ul {
  margin: 0;
  padding: 1rem 1rem 0 1rem;
}

.dropdown-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.1em 0;
}

.dropdown-menu li .exercise-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.weight,
.routine,
.tracked {
  align-self: flex-start;
}

.item {
  padding: 3px 4px;
}

.flex #plus {
  font-size: 2.5em;
  margin: 0.4em 0;
  color: rgba(255, 255, 255, 0.87);
}

.flex #plus:hover {
  color: rgba(138, 201, 38, 1);
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-flex {
  display: flex;
  width: 100%;
  max-width: 100vw;
}

.modal-content {
  overflow: auto;
  background-color: #1d2a35;
  max-height: 100vh;
  max-width: 40rem;
  width: 100%;
}

.modal-header {
  text-align: center;
  padding: 1.5%;
}

.modal-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  width: 100%;
  max-width: 100%;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.modal-body span,
.exercise-modal-body span {
  width: auto;
}

.App button {
  padding: 0.3rem;
  cursor: pointer;
  margin: 0.5rem 0.1rem;
  min-width: 5rem;
}

.flex-input {
  margin-right: 0.3rem;
}

#notes {
  align-self: flex-start;
}

.dropdown-menu li div {
  display: flex;
  align-items: center;
}

.notes,
.stats {
  height: 1.3rem;
  width: 1.3rem;
  padding-left: 0.25em;
}

.notes:hover {
  cursor: pointer;
  content: url("../public/notepad-hover.png");
  transition: content 0.3s;
}

.stats:hover {
  cursor: pointer;
  content: url("../public/linegraph-hover.png");
  transition: content 0.3s;
}

@media (max-width: 1200px) {
  ::-webkit-scrollbar {
    width: 5px;
  }

  .row {
    flex-direction: column;
  }

  .container {
    width: calc(97% - 2.2em);
    padding: 1em;
  }

  .login,
  .register {
    left: calc(50% - 0.5rem);
    top: calc(50% - 2rem);
    width: 80%;
  }

  .nav .name {
    left: calc(1.7em + 50px);
    transform: none;
    width: auto;
  }

  .nav a {
    margin: 0.2em;
  }

  .graph {
    width: 60%;
  }

  .button-container {
    margin: 0.5rem 0;
  }

  .login .button-container,
  .register .button-container {
    margin-top: auto;
  }
}

@media (max-width: 800px) {
  ::-webkit-scrollbar {
    width: 2px;
  }

  .App {
    font-size: 0.9em;
  }

  .tooltip-exercise {
    left: 9%;
  }

  .home-flex {
    flex-direction: column;
  }

  .profile .spec {
    width: 100%;
  }

  .walkthrough-img {
    width: 30px;
    height: 30px;
  }

  .nav .name {
    left: calc(2em + 30px);
    font-size: 1.4em;
  }

  .home {
    width: calc(100vw - 4rem);
    font-size: 0.5rem;
    border-radius: 0;
  }

  .modal-body .modal-button {
    min-width: 4rem;
    padding: 0.1rem;
  }

  .modal-content {
    font-size: 0.8rem;
  }

  #wider {
    width: 10em;
  }

  .edit {
    width: 0.8rem;
    height: 0.8rem;
  }
}

@media (max-height: 700px) {
  .register,
  .login {
    top: 50%;
  }
}

@media (max-height: 500px) {
  .App {
    font-size: 0.9em;
    min-height: calc(100vh - 4rem);
    padding: 2.5rem 0 1.5rem 0;
  }

  .nav {
    height: 2.5rem;
  }

  .walkthrough-img {
    height: 30px;
    width: 30px;
  }

  .nav .name {
    left: calc(2em + 30px);
    text-align: center;
  }

  .background-container {
    max-height: calc(100vh - 2.5rem);
    height: calc(100vh - 2.5rem);
    position: absolute;
    bottom: 0;
  }

  .exercise-modal-body,
  .modal-body {
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  }
}
